import "src/global.css";

import ThemeProvider from "src/theme";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CssBaseline } from "@mui/material";

import { Toaster } from "react-hot-toast";

import { useScrollToTop } from "src/hooks/use-scroll-to-top";

import routes from "src/routes/sections";
import { useRoutes } from "react-router-dom";

import useAuth from "./hooks/useAuth";
import AppInitPage from "./pages/app-init";

// ----------------------------------------------------------------------

export default function App() {
  const content = useRoutes(routes);
  const auth = useAuth();

  useScrollToTop();

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        {auth.isInitialized ? content : <AppInitPage />}
        <Toaster position="top-center" reverseOrder={false} />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
