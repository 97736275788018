/* eslint-disable no-async-promise-executor */

import axios from "axios";

const axiosInt = axios.create();

axiosInt.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "There is an error!"
    )
);

axios.interceptors.request.use(
  async (config) => {
    const token = axiosInt.defaults.headers.common.Authorization;

    if (token && !config.headers.Authorization)
      config.headers.Authorization = token;

    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 403) console.log("Singout user");

    return Promise.reject(error);
  }
);

export function doPost(url, payload, params, headers, cancelToken) {
  return new Promise(async (resolve, reject) => {
    if (cancelToken) await cancelToken.cancel();
    axios
      .post(url, payload, {
        params,
        headers,
      })
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err?.response?.data));
  });
}

export function doGet(url, params, headers, cancelToken, options = {}) {
  return new Promise(async (resolve, reject) => {
    if (cancelToken) await cancelToken.cancel();

    axios
      .get(url, {
        params,
        headers,
        ...options,
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => reject(err?.response?.data));
  });
}

export function doGetById(url, params, headers, cancelToken) {
  return new Promise(async (resolve, reject) => {
    if (cancelToken) await cancelToken.cancel();
    axios
      .get(url, { params, headers })
      .then(({ data }) => resolve({ ...data, data: data.data[0] }))
      .catch((err) => reject(err?.response?.data));
  });
}

export function doPut(url, payload, params, headers, cancelToken) {
  return new Promise(async (resolve, reject) => {
    if (cancelToken) await cancelToken.cancel();
    axios
      .put(url, payload, { params, headers })
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err?.response?.data));
  });
}

export function doDelete(url, params, headers, cancelToken) {
  return new Promise(async (resolve, reject) => {
    if (cancelToken) await cancelToken.cancel();
    axios
      .delete(url, { params, headers })
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err?.response?.data));
  });
}

export function doPatch(url, payload, params, headers, cancelToken) {
  return new Promise(async (resolve, reject) => {
    if (cancelToken) await cancelToken.cancel();
    axios
      .patch(url, payload, { params, headers })
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err?.response?.data?.message));
  });
}

const ApiCallers = {
  doPost,
  doGet,
  doGetById,
  doPut,
  doPatch,
  doDelete,
};

export default ApiCallers;
