import { Avatar, Box, styled } from "@mui/material";

const LogoImage = styled(Avatar)(
  () => `
        object-fit: contain;
        width: 100px;
        height: 100px;
        background-image: transparent;
`
);

// ----------------------------------------------------------------------

export default function AppInitView() {
  return (
    <Box
      sx={{
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%",
        bgcolor: "black",
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <LogoImage src="/assets/gifs/riple.gif" alt="Ventel" />
    </Box>
  );
}
