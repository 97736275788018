import { AppInitView } from "src/sections/appInit/view";

// ----------------------------------------------------------------------

export default function AppInitPage() {
  return (
    <>
      <AppInitView />
    </>
  );
}
