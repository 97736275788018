import SystemSecurityUpdateGoodRoundedIcon from "@mui/icons-material/SystemSecurityUpdateGoodRounded";
import SpaceDashboardRoundedIcon from "@mui/icons-material/SpaceDashboardRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: "Dashboard",
    path: "/",
    icon: <SpaceDashboardRoundedIcon />,
  },
  {
    title: "New Ad",
    path: "/create-ad",
    icon: <AddCircleOutlineRoundedIcon />,
  },
];

export default navConfig;
