// LOCAL
// const BASE_URL = "http://localhost:9090/local";

// DEV
// const BASE_URL = "https://api2-dev.riple.org";

// PROD
const BASE_URL = "https://api2.riple.org/v1";

export const AUTH_URL = `${BASE_URL}/auth`;

export const LOGIN_URL = `${AUTH_URL}/login`;
export const LOGOUT_URL = `${AUTH_URL}/logout`;
export const GET_LOGGED_IN_URL = `${AUTH_URL}/logged-in-user`;
export const REFRESH_TOKEN_URL = `${AUTH_URL}/refresh-token`;

export const ADMIN_ARTICLE_URL = `${BASE_URL}/articles/filter`;
export const ROLE_URL = `${BASE_URL}/articles/filter`;
export const ADS_ANALYTICS_URL = `${BASE_URL}/ads/analytics`;

// FORGET_PASSWORD_URL,
// RESET_PASSWORD_URL,
// CHANGE_PASSWORD_URL,
