import { lazy, Suspense } from "react";
import { Outlet, Navigate } from "react-router-dom";

import Authenticated from "src/components/Authenticated";

import DashboardLayout from "src/layouts/dashboard";

export const IndexPage = lazy(() => import("src/pages/app"));
export const BlogPage = lazy(() => import("src/pages/blog"));
export const UserPage = lazy(() => import("src/pages/user"));
export const LoginPage = lazy(() => import("src/pages/login"));
export const ProductsPage = lazy(() => import("src/pages/products"));
export const ReviewPage = lazy(() => import("src/pages/review"));
export const CreateAdPage = lazy(() => import("src/pages/add-ad"));
export const ViewAdPage = lazy(() => import("src/pages/view-ad"));
export const Page404 = lazy(() => import("src/pages/page-not-found"));

// ----------------------------------------------------------------------

const routeSections = [
  {
    element: (
      <Authenticated>
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </Authenticated>
    ),
    children: [
      { path: "/", element: <IndexPage />, index: true },
      { path: "user", element: <UserPage /> },
      { path: "products", element: <ProductsPage /> },
      { path: "blog", element: <BlogPage /> },
      { path: "review", element: <ReviewPage /> },
      { path: "create-ad", element: <CreateAdPage /> },
      { path: "view-ad", element: <ViewAdPage /> },
    ],
  },
  {
    path: "auth/login",
    element: <LoginPage />,
  },
  {
    path: "404",
    element: <Page404 />,
  },
  {
    path: "*",
    element: <Navigate to="/404" replace />,
  },
];

export default routeSections;
