import { useCallback, useState } from "react";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { alpha, useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormik } from "formik";
import * as Yup from "yup";

import { bgGradient } from "src/theme/css";

import Logo from "src/components/logo";
import Iconify from "src/components/iconify";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import AuthService from "src/services/Auth.service";
import toast from "react-hot-toast";
import useAuth from "src/hooks/useAuth";

// ----------------------------------------------------------------------

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
});

export default function LoginView() {
  const theme = useTheme();
  const { login } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = useCallback(async (formData, setSubmitting, reset) => {
    try {
      setSubmitting(true);

      await login(formData.email, formData.password);

      reset();
    } catch (error) {
      console.error("Error posting advertisement:", error);
    } finally {
      setSubmitting(false);
    }
  }, []);

  const { isSubmitting, ...form } = useFormik({
    initialValues: {
      email: null,
      password: null,
    },
    validationSchema,
    onSubmit: (values, { setSubmitting, resetForm, va }) => {
      onSubmit(values, setSubmitting, resetForm);
      setSubmitting(false);
    },
  });

  const workingOnIt = () => toast("We are still working on it!");

  const renderForm = (
    <form onSubmit={form.handleSubmit}>
      <Stack spacing={3}>
        <TextField
          value={form.values.email}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={form.touched.email && Boolean(form.errors.email)}
          name="email"
          label="Email"
          helperText={form.touched.email && form.errors.email}
        />

        <TextField
          value={form.values.password}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={form.touched.password && Boolean(form.errors.password)}
          helperText={form.touched.password && form.errors.password}
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ my: 3 }}
      >
        <Link variant="subtitle2" underline="hover" href="forgot-password">
          Forgot password?
        </Link>
      </Stack>

      <Button
        variant="contained"
        fullWidth
        size="large"
        type="submit"
        disabled={isSubmitting}
      >
        {isSubmitting ? <CircularProgress size={24} /> : "Login"}
      </Button>
    </form>
  );

  return (
    <Box
      sx={{
        ...bgGradient({
          direction: "to top right",
          startColor: alpha("#3800EF", 0.75),
          endColor: alpha("#00EAFF", 0.75),
        }),
        height: 1,
      }}
    >
      <Logo
        sx={{
          position: "fixed",
          top: { xs: 16, md: 24 },
          left: { xs: 16, md: 24 },
        }}
      />

      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 420,
          }}
        >
          <Typography variant="h4" sx={{ mt: 2, mb: 5 }}>
            Sign in to riple
          </Typography>

          {/* <Typography variant="body2" sx={{ mt: 2, mb: 5 }}>
            Don’t have an account?
            <Link variant="subtitle2" sx={{ ml: 0.5 }}>
              Get started
            </Link>
          </Typography> */}

          <Stack direction="row" spacing={2}>
            <Button
              fullWidth
              size="large"
              color="inherit"
              variant="outlined"
              sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
              onClick={workingOnIt}
            >
              <Iconify icon="eva:google-fill" color="#DF3E30" />
            </Button>

            <Button
              fullWidth
              size="large"
              color="inherit"
              variant="outlined"
              sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
              onClick={workingOnIt}
            >
              <Iconify icon="eva:facebook-fill" color="#1877F2" />
            </Button>

            <Button
              fullWidth
              size="large"
              color="inherit"
              variant="outlined"
              sx={{ borderColor: alpha(theme.palette.grey[500], 0.16) }}
              onClick={workingOnIt}
            >
              <Iconify icon="eva:linkedin-fill" color="#0E76A8" />
            </Button>
          </Stack>

          <Divider sx={{ my: 3 }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              OR
            </Typography>
          </Divider>

          {renderForm}
        </Card>
      </Stack>
    </Box>
  );
}
